/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "reportedPromptsApi",
            "endpoint": "https://8cixave2lk.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "version": "1",
    "cognitoConfig": {
        "identityPoolName": "overflowpromptse96f135b_identitypool_e96f135b",
        "allowUnauthenticatedIdentities": false,
        "resourceNameTruncated": "overfle96f135b",
        "userPoolName": "overflowpromptse96f135b_userpool_e96f135b",
        "autoVerifiedAttributes": [
            "email"
        ],
        "mfaConfiguration": "OFF",
        "mfaTypes": [
            "SMS Text Message"
        ],
        "smsAuthenticationMessage": "Your authentication code is {####}",
        "smsVerificationMessage": "Your verification code is {####}",
        "emailVerificationSubject": "Your verification code",
        "emailVerificationMessage": "Your verification code is {####}",
        "defaultPasswordPolicy": false,
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [],
        "requiredAttributes": [
            "email"
        ],
        "aliasAttributes": [],
        "userpoolClientGenerateSecret": false,
        "userpoolClientRefreshTokenValidity": 30,
        "userpoolClientWriteAttributes": [
            "email"
        ],
        "userpoolClientReadAttributes": [
            "email"
        ],
        "userpoolClientLambdaRole": "overfle96f135b_userpoolclient_lambda_role",
        "userpoolClientSetAttributes": false,
        "sharedId": "e96f135b",
        "resourceName": "overflowpromptse96f135b",
        "authSelections": "identityPoolAndUserPool",
        "useDefault": "defaultSocial",
        "hostedUI": true,
        "usernameAttributes": [
            "email"
        ],
        "hostedUIDomainName": "www",
        "authProvidersUserPool": [],
        "hostedUIProviderMeta": "[]",
        "userPoolGroupList": [
            "overflowprompts"
        ],
        "serviceName": "Cognito",
        "usernameCaseSensitive": false,
        "useEnabledMfas": true,
        "authRoleArn": {
            "Fn::GetAtt": [
                "AuthRole",
                "Arn"
            ]
        },
        "unauthRoleArn": {
            "Fn::GetAtt": [
                "UnauthRole",
                "Arn"
            ]
        },
        "breakCircularDependency": true,
        "dependsOn": [],
        "oAuthMetadata": "{\"AllowedOAuthFlows\":[\"code\"],\"AllowedOAuthScopes\":[\"phone\",\"email\",\"openid\",\"profile\",\"aws.cognito.signin.user.admin\"],\"CallbackURLs\":[\"https://www.overflowprompts.net/login/callback/\"],\"LogoutURLs\":[\"https://overflowprompts.net/logout-success/\"]}"
    }
};

export default awsmobile;
